exports.getCaretCoordinates = function (element, position) {
  const isFirefox = !(window.mozInnerScreenX == null)
  const properties = ['boxSizing', 'width', 'height', 'overflowX', 'overflowY', 'borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'fontStyle', 'fontVariant', 'fontWeight', 'fontStretch', 'fontSize', 'lineHeight', 'fontFamily', 'textAlign', 'textTransform', 'textIndent', 'textDecoration', 'letterSpacing', 'wordSpacing']
  // mirrored div
  let mirrorDiv = document.getElementById(element.nodeName + '--mirror-div')
  if (!mirrorDiv) {
    mirrorDiv = document.createElement('div')
    mirrorDiv.id = element.nodeName + '--mirror-div'
    document.body.appendChild(mirrorDiv)
  }

  const style = mirrorDiv.style
  const computedStyle = getComputedStyle(element)

  // default textarea styles
  style.whiteSpace = 'pre-wrap'
  if (element.nodeName !== 'INPUT') {
    // only for textarea-s
    style.wordWrap = 'break-word'
  }

  // position off-screen
  // required to return coordinates properly
  style.position = 'absolute'
  style.top = element.offsetTop + parseInt(computedStyle.borderTopWidth) + 'px'
  style.left = '400px'

  // transfer the element's properties to the div
  properties.forEach(function (prop) {
    style[prop] = computedStyle[prop]
  })

  if (isFirefox) {
    // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
    style.width = parseInt(computedStyle.width) - 2 + 'px'
    // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
    if (element.scrollHeight > parseInt(computedStyle.height)) {
      style.overflowY = 'scroll'
    }
  } else {
    // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
    style.overflow = 'hidden'
  }

  mirrorDiv.textContent = element.value.substring(0, position)
  // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
  if (element.nodeName === 'INPUT') {
    mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, '\u00a0')
  }

  var span = document.createElement('span')
  // Wrapping must be replicated *exactly*, including when a long word gets
  // onto the next line, with whitespace at the end of the line before (#7).
  // The  *only* reliable way to do that is to copy the *entire* rest of the
  // textarea's content into the <span> created at the caret position.
  // for inputs, just '.' would be enough, but why bother?
  // || because a completely empty faux span doesn't render at all
  span.textContent = element.value.substring(position) || '.'
  span.style.backgroundColor = 'lightgrey'
  mirrorDiv.appendChild(span)

  var coordinates = {
    top: span.offsetTop + parseInt(computedStyle['borderTopWidth']),
    left: span.offsetLeft + parseInt(computedStyle['borderLeftWidth'])
  }

  return coordinates
}
