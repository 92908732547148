<template>
  <v-card class="ma-5">
    <v-toolbar>
      <v-toolbar-title>Cov-Cares Alert/Note</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>This is where employees can submit an alert or note about a student. This mimics the same form that is in Cov-Cares in CBI.</p>
      <directory-search v-model="student" :person-filter="['Student']" search-label="Student Search" include-photo include-select-photo></directory-search>
      <v-row>
        <v-col v-if="categories.length > 1">
          <v-select v-model="category" :items="categories" label="Category (Required)" outlined></v-select>
        </v-col>
        <v-col>
          <v-select v-model="concern" :items="concernOptions" label="Concern Level" outlined></v-select>
        </v-col>
      </v-row>
      <v-textarea ref="commentInput" v-model="comment" label="Comment (Required)" rows="3" outlined @keydown="commentKeyDown"></v-textarea>
      <v-menu ref="mentionMenu" v-model="mentionActive" :position-x="mentionMenuX" :position-y="mentionMenuY" absolute>
        <v-list ref="mentionList" dense>
          <v-list-item v-for="item of mentionOptions" :key="item.bannerId" @click="addMention(item)">
            <v-list-item-avatar>
              <v-img :src="item.photo || '/img/no.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>@{{ item.ldap }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-checkbox v-for="(item, index) in mentions" :key="item.bannerId" :label="'Also add alert for ' + item.name" :value="item.checked" @change="(val) => mentions.splice(index, 1, { ...item, checked: val || false })" style="margin-top:0"></v-checkbox>
      <v-select v-model="course" :items="courseOptions" label="Related to Course" outlined clearable></v-select>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn color="success" :disabled="!valid" @click="addComment">Add Alert/Note</v-btn>
      <v-btn text to="/cov-cares">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { getCaretCoordinates } from '@/helpers/mentions'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { emit, root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const student = ref({})
    const term = computed({
      get: () => root.$store.state['cov-cares'].alert.term,
      set: (term) => root.$store.commit('cov-cares/setAlertTerm', term)
    })
    onMounted(async () => {
      if (term.value === '') {
        const { data } = await root.$feathers.service('system/term').find({ query: { type: 'Traditional', start: { $lte: new Date() }, end: { $gte: new Date() } } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'No active traditional term found. If this is incorrect, please contact Technology Services for assistance.' })
          root.$router.push('/cov-cares')
        } else {
          term.value = data[0].term
        }
      }
    })
    const commentInput = ref(null)
    const comment = ref('')
    const category = ref('')
    const categories = computed(() => {
      const arr = [
        { value: 'Academic', text: 'Academic Alert' },
        { value: 'CareTeam', text: 'Care Team Alert' },
        { value: 'Discipline', text: 'Discipline Note' },
        { value: 'FollowUp', text: 'Follow Up Meeting Note' },
        { value: 'Incident', text: 'Incident Report' },
        { value: 'Retention', text: 'Retention Alert' },
        { value: 'Meeting', text: 'Student Success Meeting Note' }
      ]
      return arr
    })
    const concern = ref('')
    const concernOptions = ref([{ value: 'H', text: 'High Concern' }, { value: 'M', text: 'Medium Concern' }, { value: 'L', text: 'Low Concern' }])
    const course = ref('')
    const courseOptions = ref([])

    watch(student, async () => {
      if ('value' in student.value) {
        try {
          const { data: courseData } = await root.$feathers.service('calendar/classes').find({ query: { term: term.value, 'students.directoryId': student.value.value } })
          courseOptions.value = courseData.map(({ crn, title }) => { return { text: title, value: crn } })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error loading courses for comment form: ' + e })
        }
      }
    })

    const valid = computed(() => comment.value !== '' && category.value !== '')

    async function addComment () {
      // Look up the student in the directory
      const { pidm, name: { last, first }, directoryId } = await root.$feathers.service('directory/people').get(student.value.value)
      // Add the comment to the student's record and emit an event with the new timeline
      const obj = {
        submittedBy: {
          pidm: user.value.pidm,
          directoryId: user.value.directoryId,
          name: user.value.name
        },
        pidm,
        directoryId,
        name: last + ', ' + first,
        term: term.value,
        category: category.value,
        concern: concern.value,
        comment: comment.value
      }
      if (course.value != null && course.value !== '') {
        obj.course = {
          crn: course.value,
          title: courseOptions.value.filter(({ value }) => value === course.value)[0].text
        }
      }

      let error = false
      try {
        await root.$feathers.service('cov-cares/alert').create(obj)
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error adding alert: ' + e })
        error = true
      }

      // ToDo: Handle logic for adding comments for @mentions here
      const regex = /@[a-z]+.[a-z]+/ig
      const matches = [...comment.value.matchAll(regex)]
      console.log(matches)
      if (matches.length > 0) {
        const newObj = { ...obj }
        newObj.comment = '<p style="margin-bottom:.5em">Originally posted on @' + first.toLowerCase() + '.' + last.toLowerCase() + '</p>' + comment.value
        for (const match of matches) {
          const [first, last] = match[0].substring(1).split('.')
          // Find a record matching the name and term
          const { data } = await root.$feathers.service('directory/people').find({ query: { 'name.first': { $regex: first, $options: 'i' }, 'name.last': { $regex: last, $options: 'i' } } })
          if (data.length > 0) {
            newObj.pidm = data[0].pidm
            newObj.directoryId = data[0]._id
            newObj.name = last + ', ' + first
            try {
              await root.$feathers.service('cov-cares/alert').create(newObj)
            } catch (e) {
              root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error adding alert: ' + e })
              error = true
            }
          } else {
            console.log('No record found for ' + first + ' ' + last)
          }
        }
      }
      if (!error) {
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Alert added successfully' })
        root.$router.push('/cov-cares')
      }
    }

    const mentionMenu = ref(null)
    const mentionList = ref(null)
    const mentionActive = ref(false)
    const mentionMenuX = ref(0)
    const mentionMenuY = ref(0)
    const mentionOptions = ref([])
    const mentions = ref([])
    async function commentKeyDown (e) {
      if (e.key === '@') {
        const { top, left } = getCaretCoordinates(e.srcElement, e.srcElement.selectionStart)
        mentionMenuX.value = left + commentInput.value.$parent.$el.offsetLeft + (root.$store.state.sideNavActive ? 275 : 20)
        mentionMenuY.value = top + commentInput.value.$el.offsetTop + commentInput.value.$parent.$el.offsetTop + 93
        console.log(commentInput.value.$parent.$el.offsetTop, commentInput.value.$el.offsetTop)
        const query = { person: 'Student', $sort: { ldap: 1 }, $limit: 5, $select: ['ldap', 'bannerId', 'name', 'photo'] }
        const { data } = await root.$feathers.service('directory/people').find({ query })
        mentionOptions.value = data.map(({ bannerId, ldap, name: { first, last }, photo }) => { return { bannerId, ldap, name: first + ' ' + last, photo } })
        mentionActive.value = true
      } else if (mentionActive.value) {
        if (e.key === 'Enter') {
          if (mentionOptions.value.length > 0) {
            addMention(mentionOptions.value[0])
            e.preventDefault()
          }
          mentionActive.value = false
        } else if (e.key === ' ') {
          mentionActive.value = false
        } else if (e.key === 'ArrowDown') {
          mentionList.value.$el.children[0].focus()
        } else {
          const mentionIndex = comment.value.substring(0, e.srcElement.selectionStart).lastIndexOf('@')
          const mentionString = comment.value.substring(mentionIndex + 1, comment.value.lastIndexOf(' ') > mentionIndex ? comment.value.indexOf(' ', mentionIndex) : comment.value.length) + e.key
          const query = { ldap: { $regex: mentionString, $options: 'i' }, person: 'Student', $sort: { ldap: 1 }, $limit: 5, $select: ['ldap', 'bannerId', 'name', 'photo'] }
          const { data } = await root.$feathers.service('directory/people').find({ query })
          mentionOptions.value = data.map(({ bannerId, ldap, photo, name: { first, last } }) => { return { bannerId, ldap, photo, name: first + ' ' + last } })
        }
      }
      for (let i = mentions.value.length - 1; i >= 0; i--) {
        if (comment.value.search('@' + mentions.value[i].ldap) < 0) {
          mentions.value.splice(i, 1)
        }
      }
    }

    function addMention ({ bannerId, ldap, name }) {
      const textArea = commentInput.value.$el.getElementsByTagName('textarea')[0]
      const mentionIndex = comment.value.substring(0, textArea.selectionStart).lastIndexOf('@')
      const lastIndex = comment.value.lastIndexOf(' ') > mentionIndex ? comment.value.indexOf(' ', mentionIndex) : comment.value.length
      comment.value = comment.value.substring(0, mentionIndex) + '@' + ldap + comment.value.substring(lastIndex)
      setTimeout(() => {
        textArea.selectionStart = mentionIndex + ldap.length + 1
        textArea.selectionEnd = mentionIndex + ldap.length + 1
        textArea.focus()
        mentionActive.value = false
        if (mentions.value.filter(({ bannerId: id }) => id === bannerId).length === 0) {
          mentions.value.push({ bannerId, name, ldap, checked: true })
        }
      }, 50)
    }

    return {
      user,
      student,
      term,
      commentInput,
      comment,
      category,
      categories,
      concern,
      concernOptions,
      course,
      courseOptions,
      valid,
      addComment,
      mentionMenu,
      mentionList,
      mentionActive,
      mentionMenuX,
      mentionMenuY,
      mentionOptions,
      mentions,
      commentKeyDown,
      addMention
    }
  }
}
</script>
